import React from "react";
import TitleSection from "../../components/TitleSection";
import CardAbout from "../../components/CardAbout";
import '../../style/components/sections.css';
import '../../style/components/carousel.css';
import '../../style/components/card_skill.css';


class SectionTechnos extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexCarousel : 0,
            skills : {
                0 : {
                    "slug" : "react",
                    "img" : "react.png"
                },
                1 : {
                    "slug" : "vue_js",
                    "img" : "logo_vuejs.png"
                },
                2 : {
                    "slug" : "jquery",
                    "img" : "jquery.png"
                },
                3 : {
                    "slug" : "sass",
                    "img" : "sass.png"
                },
                4 : {
                    "slug" : "bootstrap",
                    "img" : "bootstrap.svg"
                },
                5 : {
                    "slug" : "tailwind",
                    "img" : "tailwind.png"
                },
                6 : {
                    "slug" : "symfony",
                    "img" : "symfony.png"
                },
            }
        }
    }

     /**
     * Carousel -> vers la droite
     */
    nextCarouselParcours() {
        if(this.state.indexCarousel !== Object.keys(this.state.skills).length - 1) {
            this.setIndexCarousel(this.state.indexCarousel + 1);
        }
    }
    /**
     * Carousel -> vers la gauche
     */
    prevCarouselParcours() {
        if(this.state.indexCarousel !== 0) {
            this.setIndexCarousel(this.state.indexCarousel - 1);
        }
    }

    setIndexCarousel(i) {
        this.setState({
            indexCarousel : i
        })
    }

    render() {
        return (
            <div>
            <TitleSection title="Les technos que je maîtrise" />
            <div className="section-padding">
                <p className="text-center xl:text-left">En plus des fondamentaux du web tel que HTML, CSS, Javascript, ou PHP <br className="hidden md:block xl:hidden"/> voici les différentes technos du web que je maîtrise.</p>
                <div className="wrap-carousel mt-[50px]">
                    <nav className="nav-carousel">
                        <span className={"nav-left" + (this.state.indexCarousel === 0 ? " disabled" : "")} onClick={() => this.prevCarouselParcours()}>
                            <i className="fa fa-angle-left"></i>
                        </span>
                        <span className={"nav-right" + (this.state.indexCarousel === (Object.keys(this.state.skills).length - 1) ? " disabled" : "")} onClick={() => this.nextCarouselParcours()}>
                            <i className="fa fa-angle-right"></i>
                        </span>
                    </nav>
                    <div className="list-card-skills flex" style={{transform : "translateX("+(-(100 / Object.keys(this.state.skills).length) * this.state.indexCarousel)+"%)", "width" : (Object.keys(this.state.skills).length)*100+"%"}}>
                        {Object.values(this.state.skills).map((skill, i) => {
                            let url_logo = require(`../../img/skills/${skill.img}`);
                            let isActive = i === this.state.indexCarousel;
                            return (
                                <div key={skill.slug} onClick={() => this.setIndexCarousel(i)} className={"relative card-skill" + (isActive ? " card-active" : "")}>
                                    <img src={url_logo} alt={"Logo "+skill.slug}  className="p-3 img-principal"/>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <CardAbout 
                    key={this.state.indexCarousel} 
                    target = {this.state.skills[this.state.indexCarousel].slug}
                />
            </div>
        </div>
        )
    }
}


export default SectionTechnos;