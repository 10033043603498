
const CardParcours = (props) => {
    var logo = require(`../img/${props.url_img}`);
    var alt = "Logo "+props.intitule
    return (
        <div className={"card-parcours" + (props.isActive ? " card-active" : "")}>
            <div className="text-card-parcours">
                <span>{props.lieu}</span>
                <br />
                <span>{props.intitule}</span>
            </div>
            <div className="img-card-parcours">
                <img src={logo} alt={alt}/>
            </div>
            <div className="text-card-parcours">
                <span>{props.date}</span>
                <br />
                <span>{props.duree}</span>
            </div>
        </div>
    )
}

export default CardParcours;