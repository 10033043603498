import BackHome from "../components/BackHome";
import Footer from "../components/Footer";
import SectionParcours from "../sections/dev-front/SectionParcours";
import SectionTechnos from "../sections/dev-front/SectionTechnos";

const DevFront = () => {
    return (
        <section className="px-8 lg:px-14">
            <div className="mb-5 xl:mt-3 xl:mb-8 ">
                <BackHome />
            </div>
            <section className="section">
                <SectionParcours />
            </section>
            <section className="section">
                <SectionTechnos />
            </section>
            <Footer active="front" />
        </section>
    )
}

export default DevFront;