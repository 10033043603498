const ExperienceUXUILP = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "UX",
                "UI",
                "Adobe XD",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                LP MIAW
            </h3>
            <p className="opacity-40">Le renforcement des bases</p>
            <p>En licence professionnelle, la spécialité webdesigner/intégrateur m’a permis de prendre en aisance dans l’élaboration de mockups et 
                maquettes web. Dans le cadre de la formation j’ai effectué un stage de 10 semaines en tant que UX/UI designer et intégrateur.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list design-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default ExperienceUXUILP;