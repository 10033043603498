const ExperienceUXUIJenkaa = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "Adobe XD",
                "UX",
                "UI",
                "Mockups",
                "Maquettes",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Jenkaa
            </h3>
            <p className="opacity-40">Une professionnalisation de l'UX/UI design</p>
            <p>Chez Jenkaa j’ai rapidement montré mon attrait pour le design et en tant que Développeur front-end j’ai pris la responsabilité d’UX/UI designer 
                sur la plupart des missions. </p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list design-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default ExperienceUXUIJenkaa;