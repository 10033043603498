import BackHome from '../components/BackHome';
import { Link } from "react-router-dom";
import React from 'react';

class Footer extends React.Component {
    constructor(props) {
        super(props);
    }

    scrollTop() {
        window.scrollTo({
            top: 0,
            left: 0,
            behavior: 'smooth'
        });
        
    }

    render() {
        return (
            <footer className='w-full pb-8 mt-5'>
                <div className='flex flex-wrap justify-center'>
                    <div className='lg:mr-5 mb-5'>
                        <BackHome />
                    </div>
                    <div className='flex-grow'>
                        <div className='flex flex-wrap lg:flex-nowrap'>
                            <div className='flex-grow flex justify-center flex-wrap space-y-4 md:space-y-0'>
                                <span onClick={() => this.scrollTop()} className={"w-full md:w-max text-center text-cta"+(this.props.active === "front" ? " underline pointer-events-none" : "")}>
                                    <Link to="/dev-front-end">
                                        <span>Développeur Front-end</span>
                                    </Link>
                                </span>
                                <span onClick={() => this.scrollTop()} className={"w-full md:w-max text-center text-cta md:ml-4"+(this.props.active === "inte_web" ? " underline pointer-events-none" : "")}>
                                    <Link to="/integrateur-web">
                                        <span>Intégrateur web</span>
                                    </Link>
                                </span>
                                <span onClick={() => this.scrollTop()} className={"w-full md:w-max text-center text-cta md:ml-4"+(this.props.active === "design" ? " underline pointer-events-none" : "")}>
                                    <Link to="/ux-ui-designer">
                                        <span>UX/UI designer</span>
                                    </Link>
                                </span>
                            </div>
                            <p className='text-center opacity-40 border-left mt-5 lg:mt-0 w-full lg:w-max'>Désigné & développé par Nathan Chrétien - 2021</p>
                        </div>
                    </div>
                </div>
            </footer>
        )
    }
}

export default Footer;