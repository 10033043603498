import TitleSection from "../../components/TitleSection";
import '../../style/components/sections.css';

const SectionNotionsUXUI = () => {
    return(
        <div>
            <TitleSection title="Les notions que je maîtrise" />
            <div className="section-padding">
                <div className="card-about">
                    <p>À travers ces différentes expériences j’ai acquis les notions suivantes de l’UI/UX design.</p>
                    <h3 className="text-xl font-bold mb-3 lg:mb-4 xl:mb-5">Responsive :</h3>
                    <p>Un bon site, pour moi, c’est un site qui s’adapte à tout types d’écran (mobile, tablette, desktop). J’ai appris chez Jenkaa que le responsive 
                        ne concernait pas seulement les éléments visuels du site mais aussi ses contenus textuels, une bonne lisibilité est essentielle pour un site web.</p>
                    <h3 className="text-xl font-bold mb-3 lg:mb-4 xl:mb-5">Mobile first :</h3>
                    <p>La majorité des utilisateurs consultent les sites web sur leur téléphone, il est donc important de concevoir son site en “mobile first” et “mobile friendly” puis de l’adapter 
                        sur ses autres résolutions d’écrans. On retrouve cette logique lors de l’intégration.</p>
                    <h3 className="text-xl font-bold mb-3 lg:mb-4 xl:mb-5">UI :</h3>
                    <p>L’UI (user interface), à ne pas confondre avec l’UX (user experience) regroupe toutes les notions qui rendront le site web agréable visuellement.</p>
                    <ul className="skill-list design-skill">
                        <li>Hiérarchie visuelle</li>
                        <li>Couleurs</li>
                        <li>Typographie</li>
                        <li>Contrastes</li>
                    </ul>
                    <h3 className="text-xl font-bold mb-3 lg:mb-4 xl:mb-5">UX :</h3>
                    <p>Ce que je retiens de toutes mes formations et de mes différents retours d’expérience c’est que l’utilisateur doit avoir la meilleure expérience possible 
                        quel que soit l’objectif du site web ou de l’application. Il faut donc s’adapter et surtout se mettre à la place de l’utilisateur pour anticiper d’éventuels problèmes.</p>
                </div>
            </div>
        </div>
    )
}
export default SectionNotionsUXUI;