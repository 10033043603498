const InspirationAwwwards = () => {

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Awwwards
            </h3>
            <p className="opacity-40">Site internet</p>
            <p>Sur <a href="https://www.awwwards.com" target="_blank" className="text-cta">awwwards</a>, on retrouve de nombreux sites créatifs tous plus inspirants que les autres. 
                Ce site me permet de me rendre compte des nombreuses possibilités du web. En plus d’être impressionné par les créations, 
                awwwards est un site qui me rends ambitieux.</p>
        </div>
    )
}

export default InspirationAwwwards;