const InspirationDesignCourse = () => {

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Gary Simon - DesignCourse
            </h3>
            <p className="opacity-40">Chaine youtube</p>
            <p>J’ai découvert la chaîne youtube <a href="https://www.youtube.com/c/designcourse" target="_blank" className="text-cta">DesignCourse</a> en faisant ma veille. J’aime beaucoup la pédagogie de Gary Simon et les différents sujets abordés 
                sur sa chaîne. Je la conseille fortement tout comme ses formations, notamment celle sur l’UI/UX que j’ai achetée et qui m’a apportée beaucoup sur 
                les notions que j’ai citées précédemment.</p>
        </div>
    )
}

export default InspirationDesignCourse;