const ExperienceInteJenkaa = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "HTML",
                "CSS",
                "Javascript",
                "jQuery",
                "Vuejs",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Jenkaa
            </h3>
            <p className="opacity-40">Une professionnalisation de l’intégration web</p>
            <p>Chez Jenkaa j’ai effectué de nombreuses missions d’intégration. En tant que seul développeur front-end de l’agence et grâce à mon aisance acquise précédemment 
                j’ai obtenu la confiance de Jenkaa pour réaliser la majorité des missions d’intégration, de la création de sites vitrine, comme celui de l’un de nos clients
                <a href="https://www.mooveus.fr" target="_blank" className="text-cta">Moovéus</a> à la mise en place de Blog en passant par l’intégration d’applications web internes.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default ExperienceInteJenkaa;