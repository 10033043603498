const AboutUIris = () => {

    const skills = [
        {
            "type" : "dev",
            "list" : [
                "PHP",
                "SQL",
                "API Google suite",
            ]
        },
    ]

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                U GIE IRIS
            </h3>
            <p className="opacity-40">Novembre 2019 - Janvier 2020</p>
            <p>Ma première expérience professionnelle fut pour la société <a href="https://ugieiris.fr" target="_blank" className="text-cta">U GIE IRIS</a>, responsable de l’infrastructure et du système informatique des magasins U. 
                Durant 2 mois j’ai été développeur PHP. J’ai pu prendre en main l’API de la suite Google et le développement PHP orienté objet.</p>
            {skills.map((skill_list) => {
                return(
                    <ul className="skill-list dev-skill">
                        {skill_list.list.map((skill) => (
                            <li>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutUIris;