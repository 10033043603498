import BackHome from "../components/BackHome";
import TitleSection from "../components/TitleSection";
import SectionExperienceInteWeb from "../sections/inte-web/SectionExperienceInteWeb";
import SectionNotionsInteWeb from "../sections/inte-web/SectionNotionsInteWeb";
import '../style/components/sections.css';
import picto_responsive from '../img/picto_responsive.png';
import Footer from "../components/Footer";

const InteWeb = () => {
    return (
        <section className="px-8 lg:px-14">
            <div className="mb-5 xl:mt-3 xl:mb-8 ">
                <BackHome />
            </div>
            <section className="section">
                <TitleSection title="Une passion pour l'intégration" />
                <div className="section-padding">
                    <div className="grid grid-cols-12 gap-3 xl:gap-5">
                        <div className="col-span-12 lg:col-span-8">
                            <div className="card-about h-full">
                                <div className="flex h-full items-center">
                                    <p> La grande majorité des projets que j’ai réalisés depuis le début de mon aventure dans le web présentait un aspect d’intégration. En plus de maîtriser de plus en plus ce domaine, ma passion du web se retranscrit à travers la passion pour l’intégration web.</p>
                                </div>
                            </div>
                        </div>
                        <div className="col-span-12 lg:col-span-4">
                            <div className="flex h-full items-center bg-white rounded">
                                <img src={picto_responsive} className="rounded w-max mx-auto " alt="Picto responsive" />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="section">
                <SectionExperienceInteWeb />
            </section>
            <section className="section">
                <SectionNotionsInteWeb />
            </section>
            <Footer active="inte_web" />
        </section>
    )
}

export default InteWeb;