import BackHome from "../components/BackHome";
import '../style/components/sections.css';
import SectionExperienceUXUI from "../sections/ux-ui/SectionExperienceUXUI";
import SectionNotionsUXUI from "../sections/ux-ui/SectionNotionsUXUI";
import SectionVeille from "../sections/ux-ui/SectionVeille";
import Footer from "../components/Footer";

const UXUI = () => {
    return (
        <section className="px-8 lg:px-14">
            <div className="mb-5 xl:mt-3 xl:mb-8 ">
                <BackHome />
            </div>
            <section className="section">
                <SectionExperienceUXUI />
            </section>
            <section className="section">
                <SectionNotionsUXUI />
            </section>
            <section className="section">
                <SectionVeille />
            </section>
            <Footer active="design" />
        </section>
    )
}

export default UXUI;