import React from "react"

const AboutSass = () => {

    const skills = [
        {
            "id" : 1,
            "type" : "dev",
            "list" : [
                "Imbrication",
                "Mixins",
            ]
        },
    ]

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                SASS
            </h3>
            <p className="opacity-40">Framework CSS</p>
            <p>SASS fait partie des technologies que je maîtrise. Framework CSS, il permet une très bonne structuration 
                et lisibilité du code. J’ai appris ce framework en DUT et j’ai continué de l’utiliser sur quelques projets.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill" >
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutSass;