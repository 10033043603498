import React from "react";
import { Link } from "react-router-dom";
import ButtonPrimary from '../components/ButtonPrimary'
import '../style/home.css'
import cv from "../pdf/CV_nathan_chretien.pdf"

class Home extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            navs : [
                {
                    "nom" : "Développeur Front-end",
                    "url" : "dev-front-end",
                    "skills" : "skills_dev_front",
                },
                {
                    "nom" : "Intégrateur web",
                    "url" : "integrateur-web",
                    "skills" : "skills_inte_web",
                },
                {
                    "nom" : "UX/UI designer",
                    "url" : "ux-ui-designer",
                    "skills" : "skills_ui_ux",
                }
            ],
            skills_dev_front : [ "HTML/CSS",  "JAVASCRIPT", "JQUERY",  "AJAX", "REACT", "VUEJS", "PHP", "SYMFONY", ],
            skills_inte_web : [ "HTML/CSS", "BOOTSTRAP", "TAILWIND", "RESPONSIVE", "MOBILE FIRST", "SEO", ],
            skills_ui_ux : [ "PHOTOSHOP", "ILLUSTRATOR", "ADOBE XD", "WEB DESIGN", "UI/UX", "RESPONSIVE", "MOBILE FIRST", ],
            visibleSkills : []
        }
    }
    setVisibleSkills(val) {
        this.setState({
            visibleSkills : val
        })
    }
    /**
     * On affiche les skills qui correspondent au métier survolé
     */
    showSkills(id) {
        switch(id) {
            case "skills_dev_front" :  this.setVisibleSkills(this.state.skills_dev_front)
            break;
            case "skills_inte_web" : this.setVisibleSkills(this.state.skills_inte_web)
            break;
            case "skills_ui_ux" : this.setVisibleSkills(this.state.skills_ui_ux)
            break;
        }


    }
    hideSkills () {
        this.setVisibleSkills([])
    }
    render(){
        return(
            <main className="p-8 md:pr-[375px] lg:mt-5">
                <div className="flex flex-col items-center space-y-8 md:space-y-12">
                    <nav className="flex flex-col items-center space-y-4 md:space-y-8 md:mt-[10vh]">
                        {this.state.navs.map((nav) => (
                            <div key={nav.url} className="text-2xl lg:text-3xl cursor-pointer hover:font-bold">
                                <Link  onMouseEnter={() => this.showSkills(nav.skills)} onMouseLeave={() => this.hideSkills()} key={nav.nom} skills={nav.skills}
                                className="nav-el"  to={nav.url}> {nav.nom} </Link>
                            </div>
                        ))}
                    </nav>
                    <a href={cv} download>
                        <ButtonPrimary text="Mon CV" picto="fas fa-download" />
                    </a>
                </div>
                <aside className="hidden md:block w-[275px] lg:w-[350px]"></aside>
                <ul className={ "fixed top-[150px] lg:top-[200px] right-[55px] text-right space-y-4 lg:space-y-5" + (this.state.visibleSkills.length ? " visible" : " hidden") }>
                    {this.state.visibleSkills.map((skill) => (<li key={skill} className="text-2xl lg:text-4xl anim-skill-aside opacity-20 aside-skill">{skill}</li>))}
                </ul>
                <div className="md:hidden fixed left-0 bottom-0 space-y-3 w-100 py-[20px] bg-green-to-light" id="anim-skills">
                    <div className="animated-infinite-list flex">
                        <ul className="flex">
                            { this.state.skills_dev_front.map((skill) => (<li key={skill} className="skill-el">{skill}</li>))}
                        </ul>
                        <ul className="flex">
                            { this.state.skills_dev_front.map((skill) => ( <li key={skill} className="skill-el">{skill}</li> ))}
                        </ul>
                    </div>
                    <div className="animated-infinite-list flex">
                        <ul className="flex">
                            { this.state.skills_inte_web.map((skill) => ( <li key={skill} className="skill-el">{skill}</li> ))}
                        </ul>
                        <ul className="flex">
                            { this.state.skills_inte_web.map((skill) => ( <li key={skill} className="skill-el">{skill}</li> ))}
                        </ul>
                    </div>
                    <div className="animated-infinite-list flex">
                        <ul className="flex">
                            { this.state.skills_ui_ux.map((skill) => ( <li key={skill} className="skill-el">{skill}</li> ))}
                        </ul>
                        <ul className="flex">
                            { this.state.skills_ui_ux.map((skill) => ( <li key={skill} className="skill-el">{skill}</li> ))}
                        </ul>
                    </div>
                </div>
            </main>
        )
    }
}

export default Home;