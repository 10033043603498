const ExperienceInteLP = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "HTML",
                "CSS",
                "Javascript",
                "PHP",
                "Wordpress",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                LP MIAW
            </h3>
            <p className="opacity-40">Le renforcement des bases</p>
            <p>En licence professionnelle j’ai pu renforcer les bases acquises précédemment à travers divers projets web.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
            <p>J’y ai effectué un stage de 10 semaines dans lequel j’ai pu découvrir l’intégration et le développement mobile sur le framework Ionic.</p>
        </div>
    )
}

export default ExperienceInteLP;