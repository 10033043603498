const AboutTailwind = () => {

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Tailwind
            </h3>
            <p className="opacity-40">Framework CSS</p>
            <p>Tout comme Bootstrap, Tailwind est un framework CSS. Je l’ai découvert sur un template que j’ai été amené à utiliser pour un des projets fars de chez Jenkaa. 
                Ce framework est un réel coup de cœur et fait partie de mes “essentiels du web”, c’est pour ça que j’ai décidé d’intégrer ce portfolio en Tailwind.</p>
        </div>
    )
}

export default AboutTailwind;