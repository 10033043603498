const ExperienceInteMMI = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "HTML",
                "CSS",
                "Javascript",
                "PHP",
                "TWIG",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                DUT MMI
            </h3>
            <p className="opacity-40">Les bases de l’intégration web</p>
            <p>Lors de mes deux années de DUT j’ai découvert les bases de l’intégration web et j’ai pu les mettre à profit dans différents projets.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
            <p>La deuxième année de cette formation j’ai effectué un stage de 10 semaines dans lequel j’ai eu la responsabilité de mener à bien quelques missions d’intégration.</p>
        </div>
    )
}

export default ExperienceInteMMI;