import '../style/components/buttons.css'

const ButtonPrimary = (props) => {
    let text = props.text;
    let picto = props.picto;
    let class_button = props.class_button;
    let tel_to = props.tel_to;
    let mail_to = props.mail_to;

    if(tel_to !== undefined) {
        return (
            <a href={"tel:"+(tel_to)} className={"button btn-primary bg-white " + (class_button !== undefined ? class_button : "")}>
                <i className={picto}></i>
                <span className='truncate'>{text}</span>
            </a>
        )
    } else if(mail_to !== undefined) {
        return (
            <a href={"mailto:"+(mail_to)} className={"button btn-primary bg-white " + (class_button !== undefined ? class_button : "")}>
                <i className={picto}></i>
                <span className='truncate'>{text}</span>
            </a>
        )
    } else {
        return (
            <button className={"button btn-primary bg-white " + (class_button !== undefined ? class_button : "")}>
                <i className={picto + " mr-[10px]"}></i>
                <span className='inline-block w-max'>{text}</span>
            </button>
        )
    }
}

export default ButtonPrimary;