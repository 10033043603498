import { Link } from "react-router-dom";

const Error404 = () => {
    return (
        <section className="px-8 lg:px-14">
            <div className="section-padding">
                <h2 className="text-3xl md:text-4xl font-bold text-center mt-[20vh]">Vous êtes perdu ?</h2>
                <p className="text-center mt-3">
                    <span>Pas de panique,</span>
                    <span className="text-cta ml-1">
                        <Link to="/">
                            <span>cliquez ici</span>
                        </Link>
                    </span>
                </p>
            </div>
        </section>
    )
}

export default Error404;