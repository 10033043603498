import React from "react";

class AboutMMI extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            skills : {
                0 : [
                    {
                        "id" : 1,
                        "type" : "dev",
                        "list" : [
                            "HTML/CSS",
                            "Javascript",
                            "jQuery",
                            "PHP",
                            "SQL",
                        ]
                    },
                    {
                        "id" : 2,
                        "type" : "design",
                        "list" : [
                            "Photoshop",
                            "Illustrator",
                        ]
                    },
                ],
                1 : [
                    {
                        "id" : 3,
                        "type" : "dev",
                        "list" : [
                            "Vue.js",
                            "React",
                            "Angular"
                        ]
                    }
                ],
            }
        }
    }

    render() {
        return(
            <div className="card-about">
                <h3> 
                    <i className="fa fa-info-circle"></i>
                    DUT MMI
                </h3>
                <p className="opacity-40">Septembre 2016 - Août 2018</p>
                <p>Anciennement DUT SRC (Services et réseaux de communication), le DUT MMI (Métiers du Multimédia et de l’Internet) est une formation très complète qui m’a enseigné les fondamentaux du web, du graphisme et de la communication.</p>
                {this.state.skills[0].map((skill_list) => {
                    let class_el = skill_list.type === "dev" ? "dev-skill" : "design-skill";
                        return(
                        <ul key={skill_list.id} className={"skill-list " + (class_el)}>
                            {skill_list.list.map((skill) => (
                                <li key={skill}>{skill}</li>
                            ))}
                        </ul>
                    )
                })}
                <p>Je m’y suis découvert une passion pour le développement web et je m’y suis spécialisé lors du dernier semestre où j’ai pu approfondir les fondamentaux du web et ses différents frameworks.</p>
                {this.state.skills[1].map((skill_list) => {
                    let class_el = skill_list.type === "dev" ? "dev-skill" : "design-skill";
                    return(
                        <ul key={skill_list.id} className={"skill-list " + (class_el)}>
                            {skill_list.list.map((skill) => (
                                <li key={skill}>{skill}</li>
                            ))}
                        </ul>
                    )
                })}
                <p>Lors de cette formation j’ai effectué un stage de 10 semaines dans le pôle web d'une agence d’édition de St-Berthevin (53), l’agence <a href="https://agence-api.ouest-france.fr/societe/desk" target="_blank" className="text-cta">DESK</a>. Mes missions étaient composées d’intégration web et de webdesign.</p>
            </div>
        )
    }
}

export default AboutMMI;