import '../style/components/titles.css';

const TitleSection = (props) => {
    let title = props.title;
    return (
        <div className="title-section">
            <h2>{title}</h2>
        </div>
    )
}
export default TitleSection;