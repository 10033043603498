import React from "react";
import ButtonPrimary from "./ButtonPrimary";

class TopBar extends React.Component {
    constructor(props) {
        super(props)
    }

    displayContact() {
        this.props.displayContact(1)
    }

    render() {
        return(
            <div className="p-8 lg:px-14">
                <div className="grid grid-cols-12 gap-5 justify-between wrap align-center">
                    <div className="col-span-12 md:col-span-6 lg:col-span-8 space-y-1 lg:space-y-3">
                        <div className="h-[8px] lg:h-[12px] w-36 lg:w-[250px] grid grid-cols-3">
                            <span className="bg-green col-span-1"></span>
                            <span className="bg-blue col-span-1"></span>
                            <span className="bg-light col-span-1"></span>
                        </div>
                        <h1 className="font-bold text-3xl lg:text-4xl">Nathan Chrétien <span className="hidden">est développeur front-end</span></h1>
                        <div className="h-[8px] lg:h-[12px] w-48 lg:w-[300px] grid">
                            <span className="bg-green-to-light"></span>
                        </div>
                    </div>
                    <div className="col-span-12 md:col-span-6 lg:col-span-4">
                        <div className="flex flex-wrap md:justify-end lg:mt-5 items-center">
                            <div className="mr-3" onClick={()=> this.displayContact()} >
                                <ButtonPrimary
                                    text="Contactez moi"
                                    picto="far fa-user"
                                />
                            </div>
                            <a href="https://www.linkedin.com/in/nathan-chrétien-a76750150/" target="_blank" className="picto_linkedin">
                                <i className="fab fa-linkedin picto_linkedin"></i>
                            </a>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}


export default TopBar;