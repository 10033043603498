import '../style/components/cardsAbout.css';
import AboutUIris from "./parcours/AboutUIris";
import AboutJenkaa from "./parcours/AboutJenkaa";
import AboutLP from "./parcours/AboutLP";
import AboutMMI from "./parcours/AboutMMI";
import AboutReact from "./skill/AboutReact";
import AbouteVue from "./skill/AboutVue";
import AboutJquery from "./skill/AboutJquery";
import AboutSass from "./skill/AboutSass";
import ContactCard from "./ContactCard";
import AboutTailwind from "./skill/AboutTailwind";
import AboutBootstrap from "./skill/AboutBootstrap";
import AboutSymfony from './skill/AboutSymfony';
import ExperienceInteMMI from './experiences/ExperienceInteMMI';
import ExperienceInteLP from './experiences/ExperienceInteLP';
import ExperienceInteJenkaa from './experiences/ExperienceInteJenkaa';
import ExperienceIntePerso from './experiences/ExperienceIntePerso';
import ExperienceUXUIMMI from './experiences/ExperienceUXUIMMI';
import ExperienceUXUILP from './experiences/ExperienceUXUILP';
import ExperienceUXUIJenkaa from './experiences/ExperienceUXUIJenkaa';
import InspirationDesignCourse from './inspirations/InspirationDesignCourse';
import InspirationBehanceDribbble from './inspirations/InspirationBehanceDribbble';
import InspirationAwwwards from './inspirations/InspirationAwwwards';

const CardAbout = (props) => {
    const infosParcours = props.target;

    switch(infosParcours) {
        case "u_iris":
            return(
                <AboutUIris />
            )
        case "jenkaa":
            return(
                <AboutJenkaa />
            )
        case "lp":
            return(
                <AboutLP />
            )
        case "mmi":
            return(
                <AboutMMI />
            )
        case "contact":
            return(
                <ContactCard />
            )
        case "react":
            return(
                <AboutReact />
            )
        case "vue_js":
            return(
                <AbouteVue />
            )
        case "jquery":
            return(
                <AboutJquery />
            )
        case "sass":
            return(
                <AboutSass />
            )
        case "bootstrap":
            return(
                <AboutBootstrap />
            )
        case "tailwind":
            return(
                <AboutTailwind />
            )
        case "symfony":
            return(
                <AboutSymfony />
            )
        case "experience_mmi":
            return(
                <ExperienceInteMMI />
            )
        case "experience_lp":
            return(
                <ExperienceInteLP />
            )
        case "experience_jenkaa":
            return(
                <ExperienceInteJenkaa />
            )
        case "experience_personnelle":
            return(
                <ExperienceIntePerso />
            )
        case "experience_mmi_ux":
            return(
                <ExperienceUXUIMMI />
            )
        case "experience_lp_ux":
            return(
                <ExperienceUXUILP />
            )
        case "experience_jenkaa_ux":
            return(
                <ExperienceUXUIJenkaa />
            )
        case "designcourse":
            return(
                <InspirationDesignCourse />
            )
        case "behance_dribbble":
            return(
                <InspirationBehanceDribbble />
            )
        case "awwwards":
            return(
                <InspirationAwwwards />
            )
    }    
}

export default CardAbout;