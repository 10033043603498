import React from "react";

const AboutSymfony = () => {

    const skills = [
        {
            "id" : 1,
            "type" : "dev",
            "list" : [
                "Doctrine",
                "Repository",
                "Entity",
                "Twig",
            ]
        },
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Symfony
            </h3>
            <p className="opacity-40">Framework PHP</p>
            <p>Symfony est le premier framework PHP qui m’a été enseigné. J’ai découvert la puissance de ce framework 
            sur un projet de mise en place d’une fausse boutique. Je continue de m’auto-former sur celui-ci sur mon temps libre.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill" >
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutSymfony;