import React from "react"

const AbouteVue = () => {

    const skills = [
        {
            "id" : 1,
            "type" : "dev",
            "list" : [
                "Composants",
                "Routes",
                "Fetch",
                "Animations",
            ]
        },
    ]

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Vue.js
            </h3>
            <p className="opacity-40">Framework JS</p>
            <p>Tout comme React, Vue.js est un framework JS que je maîtrise. Celui-ci a une grande importance pour moi car c’est un framework sur lequel 
                je me suis formé dans le cadre de mon CDI chez Jenkaa pour les besoins d’un projet sur ce langage. La formation que j’ai suivie était très complète, 
                accompagnée de divers mini-projets. Elle m’a permis d’ajouter Vue.js dans mon champ de compétences.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill" >
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AbouteVue;