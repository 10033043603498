const ExperienceUXUIMMI = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "Photoshop",
                "Illustrator",
                "Webdesign",
                "Mockups",
            ]
        }
    ]

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                DUT MMI
            </h3>
            <p className="opacity-40">Les bases du design</p>
            <p>En DUT j’ai appris les bases du design et des logiciels de design de la suite Adobe (Photoshop et Illustrator). Lors des différents projets durant les deux années de formation j’ai souvent joué le rôle de webdesigner.</p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list design-skill">
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default ExperienceUXUIMMI;