import React from "react";

const AboutLP = () => {

    const skills = {
        0 : [
            {
                "id" : 0,
                "type" : "dev",
                "list" : [
                    "HTML/CSS",
                    "Javascript",
                    "jQuery",
                    "Wordpress",
                    "PHP",
                ]
            },
            {
                "id" : 1,
                "type" : "design",
                "list" : [
                    "Webdesign",
                    "Motion design",
                    "3D",
                ]
            },
        ]
    }

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                LP MIAW
            </h3>
            <p className="opacity-40">Septembre 2018 - Septembre 2019</p>
            <p>La Licence Professionnelle Métiers du Multimédia : Application Web, a représenté pour moi l’approfondissement des notions vues lors de mes deux premières années de formation et surtout la professionnalisation de mon usage des technologies du web à travers les nombreux projets. J’ai opté pour le parcours Webdesigner/Intégrateur web.</p>
            {skills[0].map((skill_list) => {
                let class_el = skill_list.type === "dev" ? "dev-skill" : "design-skill";
                return(
                    <ul key={skill_list.id} className={"skill-list " + (class_el)}>
                        {skill_list.list.map((skill) => (
                            <li>{skill}</li>
                        ))}
                    </ul>
                )
            })}
            <p>J’ai effectué un stage de 10 semaines pour le musée de Châtellerault dans lequel j’ai pu découvrir le framework mobile Ionic en réalisant des missions d’UX/UI designer et intégrateur web pour la refonte de leur application mobile.</p>
        </div>
    )
}

export default AboutLP;