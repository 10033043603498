import React from 'react';
import '../style/components/modal.css';
import ButtonPrimary from './ButtonPrimary';

class ModalContact extends React.Component {
    constructor(props) {
        super(props);
    }

    hideContact() {
        this.props.hideContact(0)
    }

    render() {
        return(
            <div className="modal">
                <div className="modal-bg" onClick={()=> this.hideContact()}></div>
                <div className="modal-content">
                    <div className='flex justify-between items-center'>
                        <h3 className='text-xl font-bold'>Contactez-moi</h3>
                        <div onClick={()=> this.hideContact()} >
                            <i className='fa fa-times text-lg text-cta hover:rotate-180 ease-in-out duration-300 cursor-pointer active:scale-50'></i>
                        </div>
                    </div>
                    <hr className='my-3 opacity-10'/>
                    <p className='mb-3'>Je serai ravi de pouvoir discuter de mon profil et de mon avenir professionnel.</p>
                    <div className="flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:space-x-3">
                        <div className="w-100 max-w-max">
                            <ButtonPrimary 
                                picto="fa fa-phone"
                                text="06 14 87 34 38"
                                tel_to="+33614873438"
                            />
                        </div>
                        <div className="w-100 max-w-max">
                            <ButtonPrimary 
                                mail_to="nathan.chretien2@gmail.com"
                                text="nathan.chretien2@gmail.com"
                                picto="fa fa-envelope"
                            />
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

// const ModalContact = (props) => {
//     return(
//         <div className="modal">
//             <div className="modal-bg" onClick={()=> props.hideContact(0)}></div>
//             <div className="modal-content">
//                 <div className='flex justify-between items-center'>
//                     <h3 className='text-xl font-bold'>Contactez-moi</h3>
//                     <div onClick={()=> props.hideContact(0)} >
//                         <i className='fa fa-times text-lg text-cta hover:rotate-180 ease-in-out duration-300 cursor-pointer active:scale-50'></i>
//                     </div>
//                 </div>
//                 <hr className='my-3 opacity-10'/>
//                 <p className='mb-3'>Je serai ravi de pouvoir discuter de mon profil et de mon avenir professionnel.</p>
//                 <div className="flex flex-col xl:flex-row space-y-3 xl:space-y-0 xl:space-x-3">
//                     <div className="w-max">
//                         <ButtonPrimary 
//                             picto="fa fa-phone"
//                             text="06 14 87 34 38"
//                             tel_to="+33614873438"
//                         />
//                     </div>
//                     <div className="w-max">
//                         <ButtonPrimary 
//                             mail_to="nathan.chretien2@gmail.com"
//                             text="nathan.chretien2@gmail.com"
//                             picto="fa fa-envelope"
//                         />
//                     </div>
//                 </div>
//             </div>
//         </div>
//     )
// }

export default ModalContact;