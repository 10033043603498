const InspirationBehanceDribbble = () => {

    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Behance / Dribbble
            </h3>
            <p className="opacity-40">Site internet</p>
            <p><a href="https://www.behance.net" target="_blank" className="text-cta">Behance</a> et <a href="https://www.dribbble.com" target="_blank" className="text-cta">Dribbble</a> sont deux sites 
            qui puisent leurs forces dans leurs communautés. On y retrouve de nombreux projets dont des projets web qui me permettent d’être à jour sur les tendances web et qui représentent une inspiration pour mes différentes missions d’UI/UX chez Jenkaa.</p>
        </div>
    )
}

export default InspirationBehanceDribbble;