const AboutReact = () => {

    const skills = [
        {
            "id" : 1,
            "type" : "dev",
            "list" : [
                "Composants",
                "JSX",
                "Routes",
                "Axios",
                "API",
            ]
        },
    ]

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                React
            </h3>
            <p className="opacity-40">Framework JS</p>
            <p>React est un des frameworks JS que je maîtrise. Pour moi il représente de nombreux avantages : il permet de mettre en place un code structuré, 
                dynamique et flexible. J’ai pris en main ce framework lors de mes différentes formations, puis j’ai appris à le maîtriser en me formant sur le net 
                (documentation, veille sur youtube) et en faisant des projets personnels comme ce portfolio. </p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill" >
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutReact;