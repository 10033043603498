const AboutJenkaa = () => {

    const skills = {
        0 : [
            {
                "type" : "dev",
                "list" : [
                    "HTML/CSS",
                    "Javascript",
                    "jQuery",
                    "Vue.js",
                    "Bootstrap",
                    "Tailwind",
                ]
            },
        ],
        1 : [
            {
                "type" : "design",
                "list" : [
                    "UX/UI design",
                    "Responsive design",
                    "Adobe XD",
                ]
            },
        ]
    }

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Jenkaa
            </h3>
            <p className="opacity-40">Janvier 2020 - Aujourd'hui</p>
            <p>En janvier 2020, j’ai obtenu mon premier CDI en tant que développeur front-end chez Jenkaa. Ces deux années d’expériences ont été très enrichissantes avec les différentes missions que j’ai effectuées (sites vitrines, web apps, blog). 
                Les technos utilisées chez Jenkaa sont HTML/CSS, jQuery, Bootstrap ou encore Tailwind. Pour un projet à venir je me suis auto-formé sur le framework Vue.js.</p>
            {skills[0].map((skill_list) => {
                let class_el = skill_list.type === "dev" ? "dev-skill" : "design-skill";
                return(
                    <ul className={"skill-list " + (class_el)}>
                        {skill_list.list.map((skill) => (
                            <li>{skill}</li>
                        ))}
                    </ul>
                )
            })}
            <p>J’ai pris en compétences et je me suis aussi grandement professionnalisé. En tant que seul développeur front-end de l’agence j’ai rapidement pris en responsabilité dans des missions de design (Mockups, maquette, réflexions UX/UI)</p>
            {skills[1].map((skill_list) => {
                let class_el = skill_list.type === "dev" ? "dev-skill" : "design-skill";
                return(
                    <ul className={"skill-list " + (class_el)}>
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutJenkaa;