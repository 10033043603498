const AboutJquery = () => {

    const skills = [
        {
            "id" : 1,
            "type" : "dev",
            "list" : [
                "Manipulation du DOM",
                "Transitions / animations",
                "AJAX",
            ]
        },
    ]

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                jQuery
            </h3>
            <p className="opacity-40">Framework JS</p>
            <p>jQuery est le premier framework JS que j’ai appris et c’est celui que je maîtrise le plus. 
                Tous mes projets chez Jenkaa furent en jQuery. Il m’a été enseigné dès mes premières années de formation et 
                j’ai continué à acquérir de l’expérience sur ce framework depuis ce temps à travers de nombreux projets. </p>
            {skills.map((skill_list) => {
                return(
                    <ul key={skill_list.id} className="skill-list dev-skill" >
                        {skill_list.list.map((skill) => (
                            <li key={skill}>{skill}</li>
                        ))}
                    </ul>
                )
            })}
        </div>
    )
}

export default AboutJquery;