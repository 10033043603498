import ButtonPrimary from "./ButtonPrimary";

const ContactCard = () => {
    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Pourquoi pas vous ?
            </h3>
            <p>Je serai ravi de pouvoir discuter de mon profil et de mon avenir professionnel.</p>
            <div className="flex flex-col xl:flex-row items-center lg:items-start space-y-3 xl:space-y-0 xl:space-x-3">
                <div className="w-max">
                    <ButtonPrimary 
                        picto="fa fa-phone"
                        text="06 14 87 34 38"
                        tel_to="+33614873438"
                    />
                </div>
                <div className="w-max">
                    <ButtonPrimary 
                        mail_to="nathan.chretien2@gmail.com"
                        text="nathan.chretien2@gmail.com"
                        picto="fa fa-envelope"
                    />
                </div>
            </div>
        </div>
    )
}

export default ContactCard;