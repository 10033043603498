const AboutBootstrap = () => {

    return(
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Bootstrap
            </h3>
            <p className="opacity-40">Framework CSS</p>
            <p>Bootstrap est un framework CSS que j’ai pris en main en Licence professionnelle. 
            J’ai appris à le maîtriser et à utiliser la totalité de son potentiel chez Jenkaa à travers ses différentes missions d’intégration.</p>
        </div>
    )
}

export default AboutBootstrap;