import React from "react";
import CardAbout from "../../components/CardAbout";
import CardParcours from "../../components/CardParcours";
import TitleSection from "../../components/TitleSection";
import '../../style/components/carousel.css';
import '../../style/components/sections.css';

class SectionExperienceInteWeb extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexCarouselParcours : 0,
            parcours : {
                0 : {
                    "id" : 1,
                    "slug" : "experience_mmi",
                    "img" : "logo_iut_laval.jpg",
                    "lieu" : "IUT de Laval (53)",
                    "intitule" : "DUT MMI",
                    "date" : "2016 - 2018",
                    "duree" : "2 ans",
        
                },
                1 : {
                    "id" : 2,
                    "slug" : "experience_lp",
                    "img" : "logo_univ_lr.png",
                    "lieu" : "Université de La Rochelle (17)",
                    "intitule" : "LP MIAW",
                    "date" : "2018 - 2019",
                    "duree" : "1 an",
                },
                2 : {
                    "id" : 3,
                    "slug" : "experience_jenkaa",
                    "img" : "logo_jenkaa.png",
                    "lieu" : "Jenkaa - Rezé (44)",
                    "intitule" : "Développeur Front-end",
                    "date" : "Depuis 2020",
                    "duree" : "2 ans",
                },
                3 : {
                    "id" : 4,
                    "slug" : "experience_personnelle",
                    "img" : "picto_code.png",
                    "lieu" : "Rezé (44)",
                    "intitule" : "Expériences personnelles",
                    "date" : "Depuis 2016",
                    "duree" : "",
                }
            }
        }
    }

    setIndexCarouselParcours(i) {
        this.setState({
            indexCarouselParcours : i
        })
    }

    /**
     * Carousel parcours -> vers la droite
     */
     nextCarouselParcours() {
        if(this.state.indexCarouselParcours !== Object.keys(this.state.parcours).length) {
            this.setIndexCarouselParcours(this.state.indexCarouselParcours + 1);
        }
    }
    /**
     * Carousel parcours -> vers la gauche
     */
    prevCarouselParcours() {
        if(this.state.indexCarouselParcours !== 0) {
            this.setIndexCarouselParcours(this.state.indexCarouselParcours - 1);
        } 
    }

    render() {
        return(
            <div id="experience_inte">
                <TitleSection title="Mes expériences" />
                <div className="section-padding">
                    <div className="wrap-carousel">
                        <nav className="nav-carousel">
                            <span className={"nav-left" + (this.state.indexCarouselParcours === 0 ? " disabled" : "")} onClick={() => this.prevCarouselParcours()}>
                                <i className="fa fa-angle-left"></i>
                            </span>
                            <span className={"nav-right" + (this.state.indexCarouselParcours === (Object.keys(this.state.parcours).length - 1) ? " disabled" : "")} onClick={() => this.nextCarouselParcours()}>
                                <i className="fa fa-angle-right"></i>
                            </span>
                        </nav>
                        <div className="list-card-parcours" style={{transform : "translateX("+(-100 / (Object.keys(this.state.parcours).length) * this.state.indexCarouselParcours)+"%)", width : ((Object.keys(this.state.parcours).length) *100) + "%"}}>
                            {Object.values(this.state.parcours).map((the_parcours, i) => {
                                let isActive = i === this.state.indexCarouselParcours;
                                return (
                                    <div key={the_parcours.id} className="w-full" onClick={() => this.setIndexCarouselParcours(the_parcours.id - 1) }>
                                        <CardParcours
                                        duree={the_parcours.duree}
                                        date={the_parcours.date}
                                        lieu={the_parcours.lieu}
                                        intitule={the_parcours.intitule}
                                        url_img={the_parcours.img}
                                        isActive={isActive} />
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div >
                        <CardAbout 
                            isActive={this.state.indexCarouselParcours === Object.keys(this.state.parcours).length}
                            key={this.state.indexCarouselParcours} 
                            target = {this.state.parcours[this.state.indexCarouselParcours].slug}
                        />
                    </div>
                </div>
            </div>
        )
    }
}

export default SectionExperienceInteWeb;