import React from "react";
import TitleSection from "../../components/TitleSection";
import CardAbout from "../../components/CardAbout";
import '../../style/components/sections.css';
import '../../style/components/carousel.css';

class SectionVeille extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            indexCarousel : 0,
            inspirations : {
                0 : {
                    "slug" : "designcourse",
                    "img" : "designcourse.jpg"
                },
                1 : {
                    "slug" : "behance_dribbble",
                    "img" : "behance_dribbble.png"
                },
                2 : {
                    "slug" : "awwwards",
                    "img" : "awwwards.jpg"
                },
            }
        }
    }

    setIndexCarousel(i) {
        this.setState({
            indexCarousel : i
        })
    }

    /**
     * Carousel -> vers la droite
     */
     nextCarouselParcours() {
        if(this.state.indexCarousel !== Object.keys(this.state.inspirations).length - 1) {
            this.setIndexCarousel(this.state.indexCarousel + 1);
        }
    }
    /**
     * Carousel -> vers la gauche
     */
    prevCarouselParcours() {
        if(this.state.indexCarousel !== 0) {
            this.setIndexCarousel(this.state.indexCarousel - 1);
        } 
    }

    render() {
        return(
            <div>
                <TitleSection title="Veille et inspirations" />
                <div className="section-padding">
                    <div className="wrap-carousel mt-[50px]">
                        <nav className="nav-carousel">
                            <span className={"nav-left" + (this.state.indexCarousel === 0 ? " disabled" : "")} onClick={() => this.prevCarouselParcours() }>
                                <i className="fa fa-angle-left"></i>
                            </span>
                            <span className={"nav-right" + (this.state.indexCarousel === (Object.keys(this.state.inspirations).length - 1) ? " disabled" : "")} onClick={() => this.nextCarouselParcours() }>
                                <i className="fa fa-angle-right"></i>
                            </span>
                        </nav>
                        <div className="list-card-skills flex" style={{transform : "translateX("+(-(100 / Object.keys(this.state.inspirations).length) * this.state.indexCarousel)+"%)", "width" : (Object.keys(this.state.inspirations).length)*100+"%"}}>
                            {Object.values(this.state.inspirations).map((inspiration, i) => {
                                let url_logo = require(`../../img/inspirations/${inspiration.img}`);
                                let isActive = i === this.state.indexCarousel;
                                return (
                                    <div key={inspiration.slug} className={"card-skill" + (isActive ? " card-active" : "")}>
                                        <img onClick={() => this.setIndexCarousel(i) } src={url_logo} alt={"Logo "+inspiration.slug} className="p-3"/>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <CardAbout 
                    key={this.state.indexCarousel} 
                    target = {this.state.inspirations[this.state.indexCarousel].slug}
                    />
                </div>
            </div>
        )
    }
}


// const SectionVeille = () => {


//     return(
//         <div>
//             <TitleSection title="Veille et inspirations" />
//             <div className="section-padding">
//                 <div className="wrap-carousel mt-[50px]">
//                     <nav className="nav-carousel">
//                         <span className={"nav-left" + (indexCarousel === 0 ? " disabled" : "")} onClick={prevCarouselParcours}>
//                             <i className="fa fa-angle-left"></i>
//                         </span>
//                         <span className={"nav-right" + (indexCarousel === (Object.keys(inspirations).length - 1) ? " disabled" : "")} onClick={nextCarouselParcours}>
//                             <i className="fa fa-angle-right"></i>
//                         </span>
//                     </nav>
//                     <div className="list-card-skills flex" style={{transform : "translateX("+(-(100 / Object.keys(inspirations).length) * indexCarousel)+"%)", "width" : (Object.keys(inspirations).length)*100+"%"}}>
//                         {Object.values(inspirations).map((inspiration, i) => {
//                             let url_logo = require(`../../img/inspirations/${inspiration.img}`);
//                             let isActive = i === indexCarousel;
//                             return (
//                                 <div key={inspiration.slug} className={"card-skill" + (isActive ? " card-active" : "")}>
//                                     <img onClick={() => setIndexCarousel(i)} src={url_logo} alt="" className="p-3"/>
//                                 </div>
//                             )
//                         })}
//                     </div>
//                 </div>
//                 <CardAbout 
//                 key={indexCarousel} 
//                 target = {inspirations[indexCarousel].slug}
//                 />
//             </div>
//         </div>
//     )
// }

export default SectionVeille;