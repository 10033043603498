import React from "react";
import TitleSection from "../../components/TitleSection";
import '../../style/components/sections.css';

const SectionNotionsInteWeb = () => {

    const skills = [
        {
            "id" : 1,
            "list" : [
                "Mobile first",
                "Responsive",
                "Accessibilité web",
                "W3C",
                "SEO",
            ]
        }
    ]

    return(
        <div>
            <TitleSection title="Les notions que je maîtrise" />
            <div className="section-padding">
                <div className="card-about">
                    <p>À travers mes différentes expériences personnelles et professionnelles, j’ai acquis une aisance dans l’intégration web 
                    et je maitrise les notions essentielles de ce domaine, de l’accessibilité aux différentes normes du web en passant par le SEO, la notion de “mobile first” 
                    et celle du responsive qui l'accompagne.</p>
                    {skills.map((skill_list) => {
                        return(
                            <ul key={skill_list.id} className="skill-list dev-skill">
                                {skill_list.list.map((skill) => (
                                    <li key={skill}>{skill}</li>
                                ))}
                            </ul>
                        )
                    })}
                </div>
            </div>
        </div>
    )
}
export default SectionNotionsInteWeb;