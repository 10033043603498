import { Link } from "react-router-dom";

const BackHome = () => {
    return(
        <div className="text-cta">
            <Link to="/">
                <i className="fa fa-arrow-left mr-2"></i>
                <span>Retour à l'accueil</span>
            </Link>
        </div>
    )
}

export default BackHome;