
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import TopBar from './components/TopBar';
import Home from './pages/Home';
import DevFront from './pages/DevFront';
import InteWeb from './pages/InteWeb';
import Error404 from './pages/Error404';
import UXUI from './pages/UXUI';
import ModalContact from './components/ModalContact';
import react from 'react';

class App extends react.Component {
  constructor(props) {
    super(props);
    this.state = {
      showContact : 0
    }
  }

  setShowContact() {
    this.setState({
      showContact : 1
    })
  }
  setHideContact() {
    this.setState({
      showContact : 0
    })
  }

  render() {
    return(
      <div className="App">
       <TopBar displayContact={() => this.setShowContact() }/>
       <BrowserRouter>
         <Routes>
           <Route path="/" element={<Home />} />
           <Route path="/dev-front-end" element={<DevFront />} />
           <Route path="/integrateur-web" element={<InteWeb />} />
           <Route path="/ux-ui-designer" element={<UXUI />} />
           <Route path="*" element={<Error404 />} />
         </Routes>
       </BrowserRouter>
       {this.state.showContact === 1 ? <ModalContact hideContact={() => this.setHideContact() } /> : ""}
     </div>
    )
  }
}

export default App;
