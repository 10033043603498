const ExperienceIntePerso = () => {
    return (
        <div className="card-about">
            <h3> 
                <i className="fa fa-info-circle"></i>
                Expériences personnelles
            </h3>
            <p className="opacity-40">À la recherche de compétences</p>
            <p>Conscient que les entreprises recherchent des profils compétents sur les frameworks JS je me suis auto-formé sur React. 
                Rien de mieux que de réaliser un projet pour acquérir le maximum d’expérience et de bonnes pratiques, j’ai donc développé ce portfolio.</p>
        </div>
    )
}

export default ExperienceIntePerso;